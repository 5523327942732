import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthorizeService } from '../services/authorize.service';
import { InactivityService } from '../services/inactivity.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly inactivityService: InactivityService,
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.inactivityService.tryRecordActivity(state.url);
  }
}
