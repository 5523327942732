import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '../core/services/window.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  readonly lang = [
    'en-US',
    'ko'
  ];

  readonly defaultLang = 'en-US';
  private _localeId: string = this.defaultLang; // default
  private windowRef: any;

  currentLanguage$ = new BehaviorSubject(this._localeId)


  constructor(@Inject(LOCALE_ID) private localeId: string,
    private translateService: TranslateService,
    private windowService: WindowService,
  ) {
    this._localeId = localeId;
    this.windowRef = this.windowService.windowRef;
  }

  public initService(): Promise<void> {
    this._localeId = this.getCurrentLanguage();
    return this.useLanguage(this._localeId);
  }

  public useLanguage(lang: string): Promise<any> {
    const isSupported = this.lang.find((supportedLanguage) => supportedLanguage === lang);

    const newLang = isSupported ?  lang : 'en-US';
    if (isSupported) {
      localStorage.setItem('language', lang);
    } else {
      localStorage.removeItem('language');
    }
    this.currentLanguage$.next(newLang);
    return this.translateService.use(newLang).toPromise();
  }

  public getCurrentLanguage(): string {
    return localStorage.getItem('language') ||
      this.windowRef.navigator.language || 'en-US';
  }

  public translate(key: string | string[], interpolateParams?: object): string {
    return this.translateService.instant(key, interpolateParams) as string;
  }

  public getEnumTranslationDictionary<T extends {}>(
    enumType: T,
    prefix?: string
  ): Record<keyof T, string> {
    return Object.assign(
      {},
      ...Object.entries(enumType).map(([status, key]) => ({
        [key as unknown as string]: `${prefix}.${status}`,
      }))
    );
  }
}
