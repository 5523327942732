import { environment as env } from './environment.default';
import merge from 'lodash-es/merge';

export const environment = merge(env, {
  demo: true,
  environmentName: 'prod',
  plausible: 'thomisticinstitute.com',
  homeUrl: 'https://thomisticinstitute.com',
  features: {
    timeEntriesActualTime: false,
  },
  sentryDsn: 'https://13d4f3ebc5ae106419aeb603a2de9a3b@o4507651584491520.ingest.us.sentry.io/4507691589894144',
}) as typeof env;
