import { Component, OnInit } from '@angular/core';
import { UiNotificationService } from '../ui-notification.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  toastKey = this.notificationService.toastKey;

  constructor(
    private readonly notificationService: UiNotificationService,
  ) {
  }

  ngOnInit(): void {
  }
}
