<div class="page flex flex-col gap-4">
  <div *ngIf="title">
    <strong class="text-xl text-black">{{ title }}</strong>
  </div>

  <div class="text-lg" [innerHTML]="message">
  </div>
  <div class="flex justify-end pt-2"
       *ngIf="hasButton">
    <button pButton
            class="btn-primary"
            (click)="submit()">{{ buttonText }}</button>
  </div>
</div>
