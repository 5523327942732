import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class LoadingService {
  private _isLoading = new BehaviorSubject<boolean>(false);

  isLoading$: Observable<boolean>;

  get isLoading(): boolean {
    return this._isLoading.value;
  }

  private _counter = 0;

  constructor() {
    this.isLoading$ = this._isLoading.asObservable().pipe(
      distinctUntilChanged(),
    );
  }

  start() {
    this._counter++;
    if (this._counter === 1) {
      this._isLoading.next(true);
    }
  }

  finish() {
    if (this._counter > 0) {
      this._counter--;

      if (this._counter === 0) {
        this._isLoading.next(false);
      }
    }
  }
}
