import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { InactivityService } from '../services/inactivity.service';
import { TranslateService } from '@ngx-translate/core';
import { UiNotificationService } from 'src/app/features/ui-notifications/ui-notification.service';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean;
  canDeactivateMessage?: () => string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(
    private readonly notificationService: UiNotificationService,
    private readonly translateService: TranslateService,
    private readonly inactivityService: InactivityService,
  ) {
  }

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate && this.inactivityService.isActive()) {
      const canDeactivate = component.canDeactivate();
      if (canDeactivate) {
        return true;
      }
      let message;
      if (component.canDeactivateMessage) {
        message = component.canDeactivateMessage();
      }
      return this.showConfirmation(message);
    }

    return true;
  }

  async showConfirmation(message?: string) {
    return await this.notificationService.confirmAsync({
      header: this.translateService.instant('modals.confirm'),
      message: message ?? this.translateService.instant('modals.unsavedWillBeDiscarder'),
      acceptLabel: this.translateService.instant('common.yes'),
      rejectLabel: this.translateService.instant('common.no'),
    });
  }
}
