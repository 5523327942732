import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ImpersonateService } from 'src/app/core/services/impersonate.service';
import { JWTTokenService } from 'src/app/core/services/jwt-token.service';

@Injectable({
  providedIn: 'root'
})
export class AcountVerifiedGuard implements CanActivate {

  constructor(
    private jwtService: JWTTokenService,
    private impersonateService: ImpersonateService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // const phoneNumberConfirmed = this.jwtService.getPhoneNumberConfirmed();

      if (this.impersonateService.isImpersonateMode()) {
        return true;
      }

      // if (!phoneNumberConfirmed) {
      //   this.router.navigate(['/account-verification/phone-number-confirmation']);
      //   return false;
      // }

      // const emailConfirmed = this.jwtService.getEmailConfirmed();

      // if (!emailConfirmed) {
      //   this.router.navigate(['/account-verification/email-confirmation']);
      //   return false;
      // }

      return true;
  }
}
