import { Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Message } from 'primeng/api/message';
import { DialogService } from 'primeng/dynamicdialog';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class UiNotificationService {
  readonly toastKey = 'UiNotificationService';
  private readonly toastConfig: Partial<Message> = {
    key: this.toastKey,
    life: 5000,
  };

  constructor(
    private readonly messageService: MessageService,
    private readonly dialogService: DialogService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
  ) {
  }

  public success(message: string, title = this.translateService.instant('notification.success')): void {
    this.messageService.add({
      ...this.toastConfig,
      key: this.toastKey,
      severity: 'success',
      detail: message,
      summary: title,
    });
  }

  public warning(message: string, 
    title = this.translateService.instant('notification.warning')): void {
    this.messageService.add({
      ...this.toastConfig,
      key: this.toastKey,
      severity: 'warning',
      detail: message,
      summary: title,
    });
  }

  public validationError(): void {
    this.errorToast(
      this.translateService.instant('notification.submissionErrors'));
  }

  public errorToast(message: string, 
    title = this.translateService.instant('notification.error')): void {
    this.messageService.add({
      ...this.toastConfig,
      key: this.toastKey,
      severity: 'error',
      detail: message,
      summary: title,
    });
  }

  public error(error: any, title?: string, onSubmit?: () => void, onClose?: () => void): void {
    let message;
    if (typeof error === 'function') {
      message = error();
    } else if (typeof error === 'object' && error['title']) {
      message = error['title'];
    } else {
      message = error.toString();
    }
 
    this.dialogService.open(AlertDialogComponent, {
      data: {
        message,
        title,
        onSubmit,
        onClose,
      },
      showHeader:false,
    });
  }

  public alert(message: string, onSubmit?: () => void): void {
    this.dialogService.open(AlertDialogComponent, {
      data: {
        message,
        onSubmit,
      },
      showHeader: false,
    });
  }

  async confirmAsync({ message, header, acceptLabel, rejectLabel }: { message: string, header: string, acceptLabel: string, rejectLabel: string }): Promise<boolean> {
    return await new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
            message: message,
            header: header,
            acceptLabel: acceptLabel,
            rejectLabel: rejectLabel,
            icon: '',
            accept: () => {
                resolve(true);
            },
            reject: () => {
                resolve(false);
            },
        });
    });
}
}
