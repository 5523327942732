import { Injectable } from '@angular/core';
import * as AES from 'crypto-js/aes';
import * as Utf8 from 'crypto-js/enc-utf8';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  secretKey = 'ThomisticInstituteKey-asfdjsakdfjsakfsikldfsahdifousd-2022';

  constructor() {
  }

  encrypt(value: string): string {
    return AES.encrypt(value, this.secretKey).toString();
  }

  decrypt(textToDecrypt: string) {
    return AES.decrypt(textToDecrypt, this.secretKey).toString(Utf8);
  }
}
