import { Injectable } from '@angular/core';
import { JWTTokenService } from './jwt-token.service';
import { Roles } from '../constants/roles';
import { LocalStorageService } from './local-storage.service';
import { LocalizationService } from 'src/app/internationalization/localization.service';
import { ImpersonateService } from './impersonate.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeService {
  constructor(
    private readonly jwtService: JWTTokenService,
    private readonly impersonateService: ImpersonateService,
    private readonly localStorage: LocalStorageService,
    private readonly localizationService: LocalizationService
  ) {}

  isAuthenticated(): boolean {
    return this.jwtService.getToken() != null;
  }

  isUser(): boolean {
    return this.jwtService.getRoles()?.includes(Roles.User) ?? false;
  }

  isAdmin(): boolean {
    return this.jwtService.getRoles()?.includes(Roles.Admin) ?? false;
  }

  isSuperAdmin(): boolean {
    return this.jwtService.getRoles()?.includes(Roles.SuperAdmin) ?? false;
  }

  isContentManager(): boolean {
    return this.jwtService.getRoles()?.includes(Roles.ContentManager) ?? false;
  }

  isHasAccessToContent(): boolean {
    return this.isAdmin() || this.isSuperAdmin() || this.isContentManager();
  }

  canAccessMainPages(): boolean {
    const canAccessSite =
      this.isAuthenticated() &&
      /*this.jwtService.getEmailConfirmed()
            &&*/ (this.jwtService.getPhoneNumberConfirmed() ||
        this.impersonateService.isImpersonateMode());
    return canAccessSite;
  }

  getBasePath(): string {
    if (this.isSuperAdmin()) {
      return 'super-admin';
    }
    if (this.isAdmin()) {
      return 'admin';
    }
    if (this.isContentManager()) {
      return 'content-manager';
    }

    return 'user';
  }

  logout() {
    const lang = this.localizationService.getCurrentLanguage();
    this.localStorage.clear();
    this.localizationService.useLanguage(lang);
  }
}
