import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { InactivityService } from '../services/inactivity.service';
import { isApiRequest } from './interceptors.helper';

@Injectable({
  providedIn: 'root',
})
export class InactivityInterceptor implements HttpInterceptor {
  private readonly urlsToNotUse = [
    'api/track',
    'api/account/refresh-token',
    'api/statuses',
    'api/login',
  ]

  constructor(
    private readonly inactivityService: InactivityService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Records only API requests
    if (!isApiRequest(req) || !this.isValidRequestForInterceptor(req.url)) {
      return next.handle(req);
    }

    const isStillActive = this.inactivityService.tryRecordActivity();
    if (isStillActive) {
      return next.handle(req);
    }
    return throwError({ message: 'Session has expired.', url: req.url });
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    return this.urlsToNotUse.every(url => !(new RegExp(`${url}.*?`).test(requestUrl)));
  }
}
