export enum Roles {
  ContentManager = 'Content Manager',
  SuperAdmin = 'Super Admin',
  Admin = 'Admin',
  User = 'User',
}

export enum RoleLabels {
  'Content Manager' = 'Content Manager',
  'Super Admin' = 'Super Admin',
  'Admin' = 'Admin',
  'User' = 'User',
}
