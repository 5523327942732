import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class AppTitleService {
  private readonly _title = new BehaviorSubject<string>('');
  title$ = this._title.asObservable();

  private readonly _titlePrefix = new BehaviorSubject<string>(environment.titlePrefix);
  titlePrefix$ = this._titlePrefix.asObservable();

  private readonly _browserTitle = new BehaviorSubject<string | undefined>(undefined);
  browserTitle$ = this._browserTitle.asObservable();

  constructor(
    private readonly titleService: Title,
    private readonly translationService: TranslateService,
    ) {
    combineLatest([this.title$, this.titlePrefix$, this.browserTitle$])
      .pipe(untilDestroyed(this))
      .subscribe(([title, prefix, browserTitle]) => {
        const titleValue = browserTitle ?? title == "" ? title : this.translationService.instant(title);
        let titleToSet = 'ThomisticInstitute';
        if (prefix && titleValue) {
          titleToSet = `${prefix} - ${titleValue}`;
        } else if (prefix) {
          titleToSet = prefix;
        } else if (titleValue) {
          titleToSet = titleValue;
        }

        this.titleService.setTitle(titleToSet);
      });
  }

  setTitle(title: string): void {
    this._title.next(title);
  }

  setTitlePrefix(titlePrefix: string): void {
    const prefix = titlePrefix || (titlePrefix === undefined ? environment.titlePrefix : '');
    this._titlePrefix.next(prefix);
  }

  setBrowserTitle(browserTitle?: string): void {
    this._browserTitle.next(browserTitle);
  }

  set({ title, prefix, browserTitle }: { title: string, prefix: string, browserTitle?: string }) {
    this.setTitle(title);
    this.setTitlePrefix(prefix);
    this.setBrowserTitle(browserTitle);
  }
}
