import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'localDate',
})
export class LocalDatePipe implements PipeTransform {
  transform(input: Date): Date {
    return moment(input).parseZone().local(true).toDate();
  }
}
