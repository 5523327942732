import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  @Input()
  message: string;

  @Input()
  title: string;

  @Input()
  onSubmit?: () => void;

  @Input()
  onClose?: () => void;

  @ViewChild('footer')
  footer!: TemplateRef<any>;

  @Input()
  buttonText = this.translateService.instant('buttons.ok');

  @Input()
  hasButton = true;

  constructor(
    private config: DynamicDialogConfig,
    private readonly translateService: TranslateService,
    private ref: DynamicDialogRef,
  ) {
    this.message = config.data.message;
    this.title = config.data.title;
    this.onSubmit = config.data.onSubmit;
    this.onClose = config.data.onClose;
    this.ref.onClose.subscribe((isSubmitted) => {
      if (!isSubmitted && this.onClose) {
        this.onClose();
      }
    });
  }

  submit() {
    if (this.onSubmit) {
      this.onSubmit();
    }
    this.ref.close(true);
  }
}
