import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import moment from 'moment';
import { Observable } from 'rxjs';
import { LocalizationService } from '../../internationalization/localization.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private localization: LocalizationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // set the User language
    const timeZoneOffset = moment().utcOffset();
    const lan = this.localization.getCurrentLanguage();
    request = request.clone({
      setHeaders: {
        'Accept-Language': lan,
        'TimeZoneOffset': timeZoneOffset.toString(),
      },
    });
    return next.handle(request);
  }
}
