import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { UiNotificationService } from 'src/app/features/ui-notifications/ui-notification.service';
import { JWTTokenService } from './jwt-token.service';
import { LocalStorageService } from './local-storage.service';
import { LogOutService } from './log-out.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class InactivityService {
  private readonly lastActivityDateKey: string = 'last_activity_date';
  private readonly minutesBeforeTimeOut: number = environment.minutesBeforeTimeOut;
  private readonly dialogDebounceMs = 200;

  private _inactiveSubject = new Subject<string | undefined>();

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly notificationService: UiNotificationService,
    private readonly logOutService: LogOutService,
    private readonly jwtService: JWTTokenService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
  ) {
    this._inactiveSubject.asObservable().pipe(
      untilDestroyed(this),
      debounce(() => timer(this.dialogDebounceMs)),
    ).subscribe(async (next) => {
      await this.showTimeOutModal();
      this.logOutService.logout(next ?? this.router.url);
    });
  }

  recordInactive(next?: string) {
    this._inactiveSubject.next(next);
  }

  recordActivity(): void {
    this.localStorageService.set(this.lastActivityDateKey, new Date().toUTCString());
  }

  shouldTimeOutUser(): boolean {
    // const lastActivityDateString = this.localStorageService.get(this.lastActivityDateKey);
    // const lastActivityDate = Date.parse(lastActivityDateString!);
    // const millisecondsInSecond = 1000;
    // const secondsInMinute = 60;
    // const diffInMinutes = (Date.now() - lastActivityDate) / (millisecondsInSecond * secondsInMinute);
    // const shouldTimeOut = !!lastActivityDateString && diffInMinutes > this.minutesBeforeTimeOut;
    return false;
  }

  showTimeOutModal(): Promise<void> {
    return new Promise((resolve, reject) =>
      this.notificationService.error(this.translateService.instant('notification.expiration'), this.translateService.instant('notification.warning'), () => {
        resolve();
      }, () => {
        resolve();
      }));
  }

  isActive(): boolean {
    return !this.jwtService.jwtToken || !this.shouldTimeOutUser();
  }

  tryRecordActivity(next?: string): boolean {
    if (!this.jwtService.jwtToken) {
      return true;
    }

    const isActive = !this.shouldTimeOutUser();
    if (isActive) {
      this.recordActivity();
    } else {
      this.recordInactive(next);
    }
    return isActive;
  }
}
