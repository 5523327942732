import 'reflect-metadata';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { API_BASE_URL } from './app/core/api-client';
import { environment } from './environments/environment';
import moment from 'moment';
import packageJson from '../package.json';
import * as Sentry from '@sentry/angular';

// Send Date with timezone
// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function() {
  return moment(this).format();
};

export function getBaseUrl() {
  return environment.baseUrl || window.location.origin;
}

const providers = [
  { provide: API_BASE_URL, useFactory: getBaseUrl, deps: [] },
];

if (environment.production) {
  enableProdMode();
}

if (environment.sentryDsn) {
  Sentry.init({
    dsn: "https://13d4f3ebc5ae106419aeb603a2de9a3b@o4507651584491520.ingest.us.sentry.io/4507691589894144",
    release: packageJson.version,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

(window as any).thomisticinstitute = {
  build: () => ({ version: packageJson.version }),
};

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch((err) => console.log(err));
