import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { AuthorizeService } from './authorize.service';

@Injectable({
  providedIn: 'root',
})
export class LogOutService {
  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly router: Router,
    private readonly authService: AuthorizeService,
  ) {
  }

  logout(next?: string) {
    this.authService.logout();
    this.router.navigate(['/', 'auth', 'login'], {
      replaceUrl: true, queryParams: {
        next,
      },
    });
  }
}
