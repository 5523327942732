export const claims = {
  id: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
  name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',

  isInternal: 'IsInternal',
  phoneNumberConfirmed: 'PhoneNumberConfirmed',
  emailConfirmed: 'EmailConfirmed',
};
